<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left ml-3 text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              Crew Requirement Exemptions
            </div>
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Airport Filters</span>
            <span
              ><i class="fa-solid fa-bars-filter text-yellow-500 ml-3"></i
            ></span>
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li class="icon" @click="handleInitExemption">
          <a href="#">
            <span class="tooltip">New Exemption</span>
            <span><i class="fa-solid fa-plus text-blue-500"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- AUTO - LOGOUT -->
    <r-snackbar
      v-if="showLogoutWarning"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="120000"
      @close="resetTimers()"
    >
    </r-snackbar>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- EXEMPTION DETAILS DIALOG-->
    <r-modal v-if="showExemptionDialog" @close="showExemptionDialog = false">
      <div
        v-if="showExemptionDialog"
        class="p-5 flex flex-col justify-between text-sm px-3"
      >
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Exemption Details</div>
          <button @click="showExemptionDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->

        <div class="px-5">
          <div class="flex justify-between border-b border-gray-500 mb-2">
            <div class="text-2xl">
              {{ `Station: ${exemption.airportCode}` }}
            </div>

            <div class="text-2xl mb-2">
              {{ `Aircraft Type: ${exemption.aircraftType}` }}
            </div>
          </div>

          <!-- Data Table -->
          <div
            v-if="exemptionAircraft.length"
            class="relative overflow-x-auto max-h-[300px]"
          >
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3">Registration</th>
                  <th scope="col" class="px-6 py-3">Aircraft Type</th>
                  <th scope="col" class="px-6 py-3">Cargo / Passenger</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="aircraft in exemptionAircraft"
                  :key="aircraft._id"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ aircraft.registration }}
                  </td>
                  <td>{{ aircraft.type }}</td>
                  <td class="px-6 py-2">
                    {{ aircraft.cargoOnly ? "Cargo" : "Passenger" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="my-2 text-base text-blue-600">
            This exemptions applies to all aircraft.
          </div>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-3 mr-5">
          <button
            v-if="!confirmDelete"
            @click="confirmDelete = true"
            class="btn bg-red-500 text-white"
          >
            Delete Exemption
          </button>

          <button
            v-else
            @click="handleDeleteExemption"
            class="btn bg-red-500 text-white"
          >
            Confirm Delete
          </button>
        </div>
      </div>
    </r-modal>

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div
        v-if="showFiltersDialog"
        class="p-5 flex flex-col justify-between text-sm px-3"
      >
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Exemptions Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div class="px-5">
          <!-- Filter by Aircraft Type -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByAircraftType"
                id="filterByAircraftType"
                aria-describedby="filter-description"
                name="filterByAircraftType"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label
                  for="filterByAircraftType"
                  class="font-medium text-gray-900"
                  >Filter by Aircraft Type</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterByAircraftType">
              <Listbox
                as="div"
                v-model="filterSelectedAircraftType"
                class="mb-10"
              >
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Exemptions for this Aircraft Type</ListboxLabel
                >
                <div class="relative mt-2">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{
                      filterSelectedAircraftType
                    }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in filterAircraftTypeOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, filterSelectedAircraftType }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              filterSelectedAircraftType
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="filterSelectedAircraftType"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <!-- Filter by Station -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex h-6 items-center mb-2">
              <input
                v-model="filterByAirport"
                id="filterByIata"
                aria-describedby="filter-description"
                name="filterByIata"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
              />

              <div class="ml-3 text-base leading-6">
                <label for="filterByIata" class="font-medium text-gray-900"
                  >Filter by Station</label
                >
                {{ " " }}
              </div>
            </div>

            <div class="flex my-1 px-5 pb-2" v-if="filterByAirport">
              <div class="ml-3">
                <label
                  for="iata"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Show Exemption for this Station</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    v-model="filterSelectedIataCode"
                    placeholder="iata"
                    maxlength="3"
                    name="filterSelectedIataCode"
                    id="filterSelectedIataCode"
                    required
                    class="w-24 text-align-center min-h-9 width-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-1">
          <button @click="handleSaveFilters" class="btn bg-blue-500 text-white">
            Apply Filters
          </button>
        </div>
      </div>
    </r-modal>

    <!-- ADD EXEMPTION DIALOG-->
    <r-modal
      v-if="showNewExemptionDialog"
      @close="showNewExemptionDialog = false"
    >
      <div
        v-if="showNewExemptionDialog"
        class="p-5 flex flex-col justify-between text-sm px-3"
      >
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">New Exemption</div>
          <button @click="showNewExemptionDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div class="px-5">
          <!-- Filter by Aircraft Type -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex my-1 px-5 pb-2">
              <Listbox as="div" v-model="selectedAircraftType" class="mb-10">
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Add Exemption for this Aircraft Type(s)</ListboxLabel
                >
                <div class="relative mt-2">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{
                      selectedAircraftType
                    }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in aircraftTypeOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedAircraftType }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedAircraftType
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedAircraftType"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <!-- Filter by Station -->
          <div class="border-b border-gray-400 mb-5">
            <div class="flex my-1 px-5 pb-2">
              <Listbox as="div" v-model="selectedIataCodeOption" class="mb-10">
                <ListboxLabel
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Add Exemption for this Station(s)</ListboxLabel
                >
                <div class="relative mt-2">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{
                      selectedIataCodeOption
                    }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in selectedIataCodeOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedIataCodeOption }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedIataCodeOption
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedIataCodeOption"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <div v-if="selectedIataCodeOption === 'IATA Code'" class="ml-3">
                <label
                  for="iata"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >IATA Code</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    v-model="selectedIataCode"
                    placeholder="iata"
                    maxlength="3"
                    name="selectedIataCode"
                    id="selectedIataCode"
                    required
                    class="text-align-center min-h-9 width-block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveExemption"
            class="btn bg-blue-500 text-white"
          >
            Add Exemption
          </button>
        </div>
      </div>
    </r-modal>

    <div class="flex flex-col justify-between">
      <table
        v-if="filteredExemptions.length"
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th
              scope="col"
              class="px-6 py-3 cursor-pointer"
              @click="sortArrayByKey('airportCode')"
            >
              Airport Code
            </th>
            <th
              scope="col"
              class="px-6 py-3 cursor-pointer"
              @click="sortArrayByKey('aircraftType')"
            >
              Aircraft Type
            </th>
            <th
              scope="col"
              class="px-6 py-3 cursor-pointer"
              @click="sortArrayByKey('addedBy')"
            >
              Added By
            </th>
            <th
              scope="col"
              class="px-6 py-3 cursor-pointer"
              @click="sortArrayByKey('addedAt')"
            >
              Added At
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="exemption in filteredExemptions"
            :key="exemption.key"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            @click="handleShowExemptionDetails(exemption)"
          >
            <td class="px-6 py-1">{{ exemption.airportCode }}</td>

            <td class="px-6 py-1">{{ exemption.aircraftType }}</td>

            <td class="px-6 py-1">
              {{ exemption.createdBy }}
            </td>
            <td>{{ formatDateTime(exemption.createdAt) }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else class="p-5 text-2xl text-green-600">
        No exemptions found with the selected filters
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "crewRequirements",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      //-----------------------------------------Auto-Logout
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      showLogoutWarning: false,
      autologoutIn: 60,

      loading: false,
      showNewExemptionDialog: false,

      confirmDelete: false,
      exemption: null,
      showExemptionDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      exemptions: [],
      filteredExemptions: [],
      exemptionAircraft: [],

      //-----------------------------------New Exemption

      //---------------------Airport
      selectedIataCodeOptions: ["All Stations", "IATA Code"],
      selectedIataCodeOption: "IATA Code",
      selectedIataCode: null,

      //----------------------Aircraft Type
      selectedAircraftType: null,
      aircraftTypeOptions: [
        "All Aircraft Types",
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],

      //-----------------------------------Filters
      showFiltersDialog: false,

      //-------------------Airport
      filterByAirport: false,
      filterSelectedIataCode: null,

      //-----------------Aircraft Type
      filterByAircraftType: false,
      filterSelectedAircraftType: null,
      filterAircraftTypeOptions: [
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],
    };
  },

  created() {
    this.getData();
  },

  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    });

    this.setTimers();
  },

  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers);
    });

    this.resetTimers();
  },

  computed: {
    airports() {
      return this.$store.getters.airports;
    },

    aircraft() {
      return this.$store.getters.aircraft;
    },

    airportOptions() {
      return ["All Stations", ...this.$store.getters.airports];
    },

    crewRequirementsAirportFilter() {
      return this.$store.getters.crewRequirementsAirportFilter;
    },

    crewRequirementsAircraftTypeFilter() {
      return this.$store.getters.crewRequirementsAircraftTypeFilter;
    },

    errors() {
      return this.$store.getters.errors;
    },
  },

  methods: {
    setTimers() {
      this.showLogoutWarning = false;
      this.warningTimer = setTimeout(() => {
        this.snackbarColor = "red";
        this.snackbarText =
          "Are you still there? Monarch will auto-logout in 1 minute";
        this.showLogoutWarning = true;
      }, (this.autologoutIn - 1) * 60 * 1000); //59 Minutes
      this.logoutTimer = setTimeout(
        this.handleAutoLogout,
        this.autologoutIn * 60 * 1000
      ); //60 Minutes
    },

    resetTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.filteredExemptions, key);

      if (sortedAsc) {
        this.filteredExemptions.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.filteredExemptions.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    async handleAutoLogout() {
      aad.logout();
      this.$store.commit("updateUser", null);
      this.$router.push({ name: "home" });
    },

    handleGoBack() {
      this.$router.go(-1);
    },
    handleGoHome() {
      this.$router.push({ name: "home" });
    },

    handleShowExemptionDetails(exemption) {
      if (exemption.aircraftType !== "All Aircraft Types") {
        this.exemptionAircraft = this.aircraft.filter((a) => {
          return a.type === exemption.aircraftType;
        });
      } else {
        this.exemptionAircraft = [];
      }

      this.exemption = { ...exemption };
      this.showExemptionDialog = true;
    },

    async getData() {
      try {
        this.loading = true;

        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/crew-requirements`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        const exemptions = res.data.exemptions;
        this.exemptions = exemptions;

        this.$store.commit("updateCrewRequirementExemptions", exemptions);
        this.filterExemptions();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    filterExemptions() {
      let filterOne;
      let filterTwo;

      if (this.crewRequirementsAirportFilter.active) {
        filterOne = this.exemptions.filter((exemption) => {
          return (
            exemption.airportCode === "All Stations" ||
            exemption.airportCode ===
              this.crewRequirementsAirportFilter.iataCode.toUpperCase()
          );
        });
      } else {
        filterOne = [...this.exemptions];
      }

      if (this.crewRequirementsAircraftTypeFilter.active) {
        filterTwo = this.exemptions.filter((exemption) => {
          return (
            exemption.aircraftType === "All Aircraft Types" ||
            exemption.aircraftType === this.crewRequirementsAircraftTypeFilter
          );
        });
      } else {
        filterTwo = [...filterOne];
      }

      this.filteredExemptions = [...filterTwo];
    },

    handleInitExemption() {
      this.selectedAircraftType = "All Aircraft Types";
      this.selectedIataCode = null;

      this.showNewExemptionDialog = true;
    },

    handleOpenFilters() {
      //----------------------------------------Airport Filter
      if (this.crewRequirementsAirportFilter.active) {
        this.filterByAirport = true;
        this.filterSelectedIataCode =
          this.crewRequirementsAirportFilter.iataCode.slice();
      } else {
        this.filterSelectedIataCode = "ANC";
      }

      //--------------------------------------Aircraft Type
      if (this.crewRequirementsAircraftTypeFilter.active) {
        this.filterByAircraftType = true;
        this.filterSelectedAircraftType =
          this.crewRequirementsAircraftTypeFilter.type.slice();
      } else {
        if (!this.filterSelectedAircraftType) {
          this.filterSelectedAircraftType = "744";
        }
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //-----------------------------Airport Filter
      let airportFilter = {
        active: false,
        iataCode: "",
      };

      if (this.filterByAirport && this.filterSelectedIataCode) {
        const airport = this.airports.find((a) => {
          return a.iata === this.filterSelectedIataCode.toUpperCase();
        });

        if (!airport) {
          this.snackbarColor = "red";
          this.snackbarText = `No airport found for IATA Code ${this.filterSelectedIataCode}`;
          this.snackbarVisible = true;
          return;
        }
        airportFilter = {
          active: true,
          iataCode: this.filterSelectedIataCode.slice(),
        };
      }

      this.$store.commit("updateCrewRequirementsAirportFilter", airportFilter);

      //----------------------------Special Periods Filter
      let aircraftTypeFilter = {
        active: false,
        type: "",
      };

      if (this.filterByAircraftType && this.filterSelectedAircraftType) {
        aircraftTypeFilter = {
          active: true,
          type: this.filterSelectedAircraftType.slice(),
        };
      }

      this.$store.commit(
        "updateCrewRequirementsAircraftTypeFilter",
        aircraftTypeFilter
      );

      this.filterExemptions();
    },

    async handleSaveExemption() {
      this.showNewExemptionDialog = false;

      let airportCode = "All Stations";

      if (this.selectedIataCodeOption === "IATA Code") {
        if (this.selectedIataCode?.length === 3) {
          const airport = this.airports.find((a) => {
            return a.iata === this.selectedIataCode.toUpperCase();
          });

          if (!airport) {
            this.snackbarColor = "red";
            this.snackbarText = `No airport found for IATA Code ${this.filterSelectedIataCode}`;
            this.snackbarVisible = true;
            return;
          }

          airportCode = this.selectedIataCode.toUpperCase();
        } else {
          this.snackbarColor = "red";
          this.snackbarText = `Please enter a valid IATA Code`;
          this.snackbarVisible = true;
          return;
        }
      }

      this.loading = true;

      const exemption = {
        type: "Loadmaster",
        airportCode,
        aircraftType: this.selectedAircraftType,
      };

      const res = await this.$store.dispatch(
        "saveCrewRequirementExemption",
        exemption
      );

      this.loading = false;

      if (res.status !== 200) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },

    async handleDeleteExemption() {
      this.showExemptionDialog = false;
      this.confirmDelete = false;

      this.loading = true;

      const res = await this.$store.dispatch(
        "deleteCrewRequirementExemption",
        this.exemption._id
      );

      this.loading = false;

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },
  },
};
</script>
